<template>
  <div class="finance2">
    <img src="../assets/finance2.jpg" alt="" />
  </div>
</template>

<script>
export default {};
</script>

<style>
img {
  width: 100%;
  height: 100%;
}
</style>